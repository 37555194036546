import { Navigate, useRoutes } from "react-router-dom"

import Login from "../pages/authentication/login";
import Dashboard from "../pages/dashboard";

import DashboardLayout from "../layouts/dashboard-layout";
import AuthLayout from "../layouts/auth-layout";

import AuthGuard from '../guards/auth-guard';
import GuestGuard from '../guards/guest-guard';

import { AuthProvider } from "../context/auth-context";
import { DashboardProvider } from "../context/dashboard-context";

const Routes = () => {
   return useRoutes([
      {
         path: 'auth',
         element: (
            <AuthProvider>
               <GuestGuard>
                  <AuthLayout />
               </GuestGuard>
            </AuthProvider>
         ),
         children: [
            {
               element: <Navigate to={'/auth/login'} replace />,
               index: true
            },
            {
               path: 'login',
               element: <Login />
            }
         ]
      },
      {
         path: '/',
         element: (
            <AuthProvider>
               <AuthGuard>
                  <DashboardProvider>
                     <DashboardLayout />
                  </DashboardProvider>
               </AuthGuard>
            </AuthProvider>
         ),
         children: [
            {
               element: <Navigate to={'/dashboard'} replace />,
               index: true
            },
            {
               path: 'dashboard',
               element: <Dashboard />
            }
         ]
      },
      { path: '*', element: <Navigate to={'/auth/login'} replace /> }
   ]);
}

export default Routes;
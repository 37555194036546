import {
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TablePagination,
   TableRow,
   Typography
} from "@mui/material"
import { useState } from "react";
import moment from "moment";
import useDashboard from "../../../hooks/useDashboard";

export default function ClinicsTable({ ...props }) {

   const {
      fetchClinicInfo
   } = useDashboard();

   const handleChangePage = (e, newPage) => {
      props.setPage(newPage)
   };

   const rows = () => {
      if (props.clinics.length > 0) {
         props.clinics.sort((a, b) => moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1)
         return props.clinics;
      }
      return props.clinics;
   };

   const handleChangeRowsPerPage = (e) => {
      props.setRowsPerPage(parseInt(e.target.value));
      props.setPage(0);
   };

   return (
      <>
         <TableContainer sx={{ minWidth: '50vh', maxHeight: '50vh' }}>
            <Table stickyHeader>
               <TableHead>
                  <TableRow>
                     {
                        tableHeader.map((row) => (
                           <TableCell align="left" key={row.id}>
                              <Typography variant="subtitle2" color={'primary'} fontWeight={700}>{row.label}</Typography>
                           </TableCell>
                        ))
                     }
                  </TableRow>
               </TableHead>
               <TableBody>
                  {
                     rows()?.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                        .map(row => (
                           <TableRow
                              key={row.id}
                              hover
                              onClick={() => fetchClinicInfo(row.id)}
                              sx={{
                                 '&:last-child td, &:last-child th': {
                                    border: 0
                                 },
                                 cursor: 'pointer'
                              }}
                           >
                              <TableCell align="left">
                                 <Typography variant="subtitle1">{row.clinic_name}</Typography>
                                 <Typography variant="caption">Created: {moment(row.created_at).format('DD/MM/yyyy')}</Typography>
                              </TableCell>

                              <TableCell align="left">
                                 {row?.branch ?? "N/A"}
                              </TableCell>

                              <TableCell align="left">
                                 {row.address}
                              </TableCell>
                           </TableRow>
                        ))
                  }
               </TableBody>
            </Table>
         </TableContainer>

         <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={props.clinics.length}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
               mt: 5
            }}


         />
      </>
   )
}

const tableHeader = [
   { id: 1, label: 'Name' },
   { id: 2, label: 'Branch' },
   { id: 3, label: 'Address' }
]
import { authTypes } from "./authentication.types"


export const authInitialState = {
   isAuthenticated: false,
   isRequestAccessToken: false
}


export const authentication = (state = authInitialState, action) => {
   switch (action.type) {
      case authTypes.IS_REQUEST_ACCESS_TOKEN: 
         return {
            ...state,
            isRequestAccessToken: !state.isRequestAccessToken
         }
      case authTypes.AUTH_LOGIN:
         return {
            ...state,
            isAuthenticated: !state.isAuthenticated
         }
      case authTypes.VERIFY_AUTH:
         return {
            ...state,
            isRequestAccessToken: false,
            isAuthenticated: action.payload
         }
      case authTypes.AUTH_LOGOUT:
         return {
            ...state,
            isRequestAccessToken: false,
            isAuthenticated: false
         }
      default:
         return state;
   }
}
import { createContext, useEffect } from "react";
import { dashboardAction, dashboardInitialState } from '../redux';
import { useDispatch, useSelector } from 'react-redux';


const DashboardContext = createContext({
   ...dashboardInitialState,
   fetchClinics: () => {},
   fetchClinicInfo: () => {},
   removeInvoice: () => {},
   closeModal: () => {}
})

const DashboardProvider = ({ children }) => {

   const dispatch = useDispatch();

   const state = useSelector((state) => state.dashboard);

   const fetchClinics = async() => {
      await dispatch(dashboardAction.fetchClinics())
   }

   const fetchClinicInfo = async(clinicId) => {
      await dispatch(dashboardAction.fetchClinicInfo(clinicId));
   }

   const removeInvoice = async(invoiceId, clinicId) => {
      await dispatch(dashboardAction.removeInvoice(invoiceId, clinicId));
   }

   const closeModal = () => {
      dispatch(dashboardAction.closeModal());
   }

   return (
      <DashboardContext.Provider
         value={{
            ...state,
            fetchClinics,
            fetchClinicInfo,
            removeInvoice,
            closeModal
         }}
      >
         {children}
      </DashboardContext.Provider>
   )
}

export { DashboardContext, DashboardProvider }
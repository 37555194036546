import { Box, Card, Grid, Stack, TextField, Typography } from "@mui/material";
import Page from "../../../components/Page";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import useAuth from '../../../hooks/useAuth';
import VerifyToken from "./modal/verifyAccessToken";

export default function Login() {

   const [isSubmitting, setIsSubmitting] = useState(false)

   const [loginFields, setLoginFields] = useState({
      username: '',
      password: ''
   });

   const {
      isRequestAccessToken,
      login
   } = useAuth();

   const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true)
      await login(loginFields?.username, loginFields?.password);
      setIsSubmitting(false)
   }

   const handleLoginField = (e) => {
      setLoginFields({
         ...loginFields,
         [e.target.name]: e.target.value
      })
   }

   return (
      <Page title="Login">
         <form onSubmit={handleSubmit}>
            <Grid
               container
               spacing={0}
               direction={"column"}
               alignItems={"center"}
               justifyContent={"center"}
               sx={{ minHeight: '100vh' }}
            >
               <Grid item xs={3}>
                  <Box
                     component={Card}
                     width={300}
                     height={300}
                     paddingX={5}
                     paddingY={5}
                     sx={{ borderRadius: '12px' }}
                  >
                     <Stack
                        direction={'column'}
                        spacing={4}
                     >
                        <Typography variant="h5" sx={{ textAlign: 'center' }}>Margo Admin</Typography>

                        <TextField
                           variant="standard"
                           placeholder="Username"
                           fullWidth
                           name="username"
                           onChange={(e) => handleLoginField(e)}
                        />

                        <TextField
                           variant="standard"
                           placeholder="Password"
                           fullWidth
                           type="password"
                           name="password"
                           onChange={(e) => handleLoginField(e)}
                        />

                        <LoadingButton
                           variant="contained"
                           disableRipple
                           type="submit"
                           fullWidth
                           disableTouchRipple
                           loading={isSubmitting}
                        >
                           Login
                        </LoadingButton>
                     </Stack>
                  </Box>
               </Grid>
            </Grid>
         </form>

         {
            isRequestAccessToken && <VerifyToken />
         }
      </Page>
   )
}
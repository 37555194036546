import thunkMiddleware from "redux-thunk";
import reducers from "./reducers";
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";

const loggerMiddleware = createLogger();

export const store = createStore(
    reducers,
    applyMiddleware(thunkMiddleware, loggerMiddleware)
);
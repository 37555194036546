import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { Navigate } from 'react-router-dom';

GuestGuard.propTypes = {
   children: PropTypes.node
}

export default function GuestGuard({ children }) {

   const { isAuthenticated } = useAuth();

   if (isAuthenticated) {
      return <Navigate to={'/dashboard'} />
   }

   return <>{children}</>;
}
import { dashboardTypes } from "./dashboard.types"

export const dashboardInitialState = {
   isFetchingClinicDone: false,
   selectedClinicInfo: {},
   isModalOpen: false,
   clinics: []
}

export const dashboard = (state = dashboardInitialState, action) => {
   switch (action.type) {
      case dashboardTypes.FETCH_CLINICS:
         return {
            ...state,
            isFetchingClinicDone: !state.isFetchingClinicDone,
            clinics: action.payload
         };
      case dashboardTypes.IS_MODAL_OPEN:
         return {
            ...state,
            isModalOpen :!state.isModalOpen,
            selectedClinicInfo: action.payload
         }
      case dashboardTypes.IS_DELETE_INVOICE:
         return {
            ...state,
            isModalOpen: !state.isModalOpen,
            selectedClinicInfo: action.payload
         }
      default:
         return state;
   }
}
import { Box, Button, Paper, Typography, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import Page from "../../components/Page";
import useAuth from "../../hooks/useAuth";
import Iconify from "../../components/Iconify";
import ClinicsTable from "./table";
import useDashboard from '../../hooks/useDashboard';
import { useEffect, useState } from "react";
import ClinicInformationModal from "./modal";

export default function Dashboard() {

   const { logout } = useAuth();

   const {
      clinics,
      isFetchingClinicDone,
      isModalOpen,
      fetchClinics
   } = useDashboard();

   const [searchTerm, setSearchTerm] = useState("")
   const [filteredClinics, setFilteredClinics] = useState([])

   const [page, setPage] = useState(0)
   const [rowsPerPage, setRowsPerPage] = useState(5);

   const handleSearchTerm = (e) => {
      const searchClinic = clinics
         .map(row => { return row; })
         .filter(row => {
            const ClinicName = `${row.clinic_name}`.toLowerCase();
            return ClinicName.startsWith(e.target.value.toLowerCase());
         });
      setPage(0)
      setRowsPerPage(5)
      setSearchTerm(e.target.value);
      setFilteredClinics(searchClinic);
   }

   useEffect(() => {
      if (!isFetchingClinicDone) {
         fetchClinics()
      }
   }, []);

   return (
      <Page title="Dashboard">
         <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
         >
            <Typography variant="subtitle1">Admin Dashboard</Typography>
            <Button
               variant="text"
               onClick={() => logout()}
               sx={{
                  textTransform: 'none',
                  color: 'black',
                  '&:hover': {
                     background: 'none'
                  }
               }}
            >
               <Typography variant="subtitle1">Logout</Typography>
            </Button>
         </Box>

         <Box
            component={Paper}
            sx={{
               mt: 5,
               maxHeight: '100vh',
               py: 2,
               px: 2
            }}
         >
            <Box
               sx={{
                  my: 5
               }}
            >
               <Stack
                  spacing={2}
                  justifyContent={'center'}
                  alignItems={'flex-start'}
                  marginBottom={4}
               >
                  <TextField
                     name="clinic_name"
                     label="Clinic Name"
                     placeholder="Search clinic.."
                     variant='outlined'
                     onChange={(e) => handleSearchTerm(e)}
                     sx={{ width: '400px' }}
                     InputProps={{
                        startAdornment:
                           <InputAdornment position="start">
                              <Iconify icon={'material-symbols:search'} width={25} height={25} />
                           </InputAdornment>
                     }}
                  />
               </Stack>

               <ClinicsTable
                  clinics={searchTerm === "" ? clinics : filteredClinics}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
               />

            </Box>
         </Box>
         
         {
            isModalOpen && <ClinicInformationModal />
         }
      </Page>
   )
}
import axios from "axios";

const API_URL = 'https://mgdph.linneasystems.com/api';

const axiosInstance = axios.create({
   baseURL: API_URL,
});

const axiosNoAuthInstance = axios.create({
   baseURL: API_URL,
});

axiosInstance.interceptors.request.use((config) => {
   const token = "";
   if (token) {
      config.headers.Authorization = `Bearer ${token}`;
   }
   return config;
});

axiosInstance.interceptors.response.use(
   (response) => response.data,
   async (error) => {
      return Promise.reject(
         (error.response && error.response.data) || "Something went wrong"
      );
   }
);

axiosNoAuthInstance.interceptors.response.use(
   (response) => response.data,
   async (error) => {
      return Promise.reject(
         (error.response && error.response.data) || "Something went wrong"
      )
   }
);

export { axiosInstance, axiosNoAuthInstance };
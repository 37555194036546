import { createContext, useEffect } from "react";
import { authActions, authInitialState } from '../redux';
import { useDispatch, useSelector } from 'react-redux';


const AuthContext = createContext({
   ...authInitialState,
   login: () => {},
   verifyAuthToken: () => {},
   logout: () => {}
})

const AuthProvider = ({ children }) => {

   const dispatch = useDispatch();

   const state = useSelector((state) => state.authentication);

   const login = async (username, password) => {
      await dispatch(authActions.attemptLogin(username, password));
   }

   const verifyAuthToken = async (token) => {
      await dispatch(authActions.validateAccessToken(token));
   }

   const logout = () => {
      dispatch(authActions.signOut());
   }

   useEffect(() => {
      dispatch(authActions.verifyLogin());
   }, [])

   return (
      <AuthContext.Provider
         value={{
            ...state,
            login,
            verifyAuthToken,
            logout
         }}
      >
         {children}
      </AuthContext.Provider>
   )
}

export { AuthContext, AuthProvider }
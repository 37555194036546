import { axiosNoAuthInstance as axios } from '../utils/axios';

export const login = async(username, password) => {
   const payload = {
      username: username,
      password: password
   };

   const response = await axios.post('/admin/login', payload);
   return response;
}

export const verifyAccessToken = async(token) => {
   const payload = {
      token: token
   };
   
   const response = await axios.post('/admin/verify-access-token', payload);
   return response;
}

export const getClinics = async() => {
   const response = await axios.get('/admin/clinics');
   return response;
}

export const getClinicInfo = async(clinic_id) => {
   const response = await axios.get(`/admin/clinic-info/${clinic_id}`);
   return response;
}

export const deleteInvoice = async(invoiceId, clinicId) => {
   const response = await axios.post(`/admin/invoice/delete/${invoiceId}}/${clinicId}`);
   return response;
}
import Swal from "sweetalert2";
import { login, verifyAccessToken } from "../../api";
import { authTypes } from "./authentication.types"


export const authActions = {
   attemptLogin,
   verifyLogin,
   validateAccessToken,
   closeValidateAccessToken,
   signOut
}

function attemptLogin(username, password) {
   return async (dispatch) => {
      await login(username, password).then(() => {
         dispatch(success())
      }).catch(() => {
         Swal.fire({
            icon: "error",
            title: 'Invalid username and password',
         });
      })
   }

   function success() {
      return {
         type: authTypes.IS_REQUEST_ACCESS_TOKEN
      }
   }
}

function closeValidateAccessToken() {
   return dispatch => {
      dispatch({ type: authTypes.IS_REQUEST_ACCESS_TOKEN });
   }
}
function validateAccessToken(token) {
   return async dispatch => {
      await verifyAccessToken(token).then(response => {
         localStorage.setItem('admin', `${btoa(new Date())}`);
         Swal.fire({
            icon: "success",
            title: 'Token validated',
         });
         dispatch(success());
      }).catch(error => {
         Swal.fire({
            icon: "error",
            title: error?.message ?? 'Invalid Token',
         });
      })
   }

   function success() {
      return {
         type: authTypes.AUTH_LOGIN
      }
   }
}

function verifyLogin() {
   return dispatch => {
      const admin = localStorage.getItem('admin');
      if (admin) {
         dispatch(success());
      } else {
         dispatch(failed());
      }
   }

   function success() {
      return {
         type: authTypes.VERIFY_AUTH,
         payload: true
      }
   }

   function failed() {
      return {
         type: authTypes.VERIFY_AUTH,
         payload: false
      }
   }
}

function signOut() {
   return dispatch => {
      localStorage.clear();
      dispatch({
         type: authTypes.AUTH_LOGOUT
      })
   }
}
import {
   Box,
   IconButton,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TablePagination,
   TableRow,
   Typography
} from "@mui/material"
import moment from "moment";
import { useState } from "react";
import Iconify from '../../../../components/Iconify';
import useDashboard from "../../../../hooks/useDashboard";
import Swal from "sweetalert2";

export default function InvoicesTable({ ...props }) {

   const { removeInvoice } = useDashboard();

   const handleChangePage = (e, newPage) => {
      props.setPage(newPage)
   }

   const rows = () => {
      if (props.invoices.length > 0) {
         props.invoices.sort((a, b) => moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1)
         return props.invoices;
      }
      return props.invoices;
   };

   const handleChangeRowsPerPage = (e) => {
      props.setRowsPerPage(parseInt(e.target.value));
      props.setPage(0);
   }

   const handleDeleteInvoice = (invoiceId, clinicId, invoiceNumber) => {
      Swal.fire({
         icon: "question",
         title: `Are you sure you want to delete Invoice # ${invoiceNumber}?`,
         showCancelButton: true,
         confirmButtonColor: "#ed4763",
         cancelButtonColor: "#28a745",
         confirmButtonText: "Confirm Delete",
         reverseButtons: true,
         allowOutsideClick: () => !Swal.isLoading(),
      }).then(async (value) => {
         if (value.isConfirmed) {
            await removeInvoice(invoiceId, clinicId).then(() => {
               Swal.fire({
                  icon: "success",
                  title: "Successfully deleted!",
               });
            }).catch(() => {
               Swal.fire({
                  icon: "error",
                  title: "Failed to remove invoice",
               });
            })
         }
      })
   }

   return (
      <>
         {
            (rows()?.length <= 0) &&
            <Box
               display={"flex"}
               justifyContent={"center"}
               sx={{ px: 20, py: 20 }}
            >
               <Typography variant="h6">No Invoices</Typography>
            </Box>
         }
         {
            (rows()?.length > 0) &&
            <TableContainer sx={{ minWidth: '50vh', maxHeight: '50vh' }}>
               <Table stickyHeader>
                  <TableHead>
                     <TableRow>
                        {
                           tableHeader.map((row) => (
                              <TableCell align="left" key={row.id}>
                                 <Typography variant="subtitle2" color={'primary'} fontWeight={700}>{row.label}</Typography>
                              </TableCell>
                           ))
                        }
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {
                        rows()?.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                           .map(row => (
                              <TableRow
                                 key={row.id}
                                 hover
                                 sx={{
                                    '&:last-child td, &:last-child th': {
                                       border: 0
                                    },
                                    cursor: 'pointer'
                                 }}
                              >
                                 <TableCell align="left">
                                    <Typography variant="subtitle1">{row.invoice_number}</Typography>
                                 </TableCell>

                                 <TableCell align="left">
                                    {row?.status}
                                 </TableCell>

                                 <TableCell align="left">
                                    {moment(row.created_at).format('MM/DD/yyyy')}
                                 </TableCell>

                                 <TableCell align="left">
                                    <IconButton color="error" onClick={() => handleDeleteInvoice(row.id, row.clinic_id, row.invoice_number)}>
                                       <Iconify icon={'heroicons:trash'} width={20} height={20} />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           ))
                     }
                  </TableBody>
               </Table>
            </TableContainer>
         }
         <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={props.invoices?.length}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
               mt: 5
            }}
         />

      </>
   )
}

const tableHeader = [
   { id: 1, label: 'Invoice Number' },
   { id: 2, label: 'Status' },
   { id: 3, label: 'Created' },
   { id: "" }
]
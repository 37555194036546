import { dashboardTypes } from "./dashboard.types"
import { getClinicInfo, getClinics, deleteInvoice } from '../../api/index';

export const dashboardAction = {
   fetchClinics,
   fetchClinicInfo,
   removeInvoice,
   closeModal
}


function fetchClinics() {
   return async dispatch => {
      await getClinics().then(response => {
         dispatch({
            type: dashboardTypes.FETCH_CLINICS,
            payload: response.result
         });
      });
   };
};

function fetchClinicInfo(clinicId) {
   return async dispatch => {
      await getClinicInfo(clinicId).then(response => {
         dispatch({
            type: dashboardTypes.IS_MODAL_OPEN,
            payload: response.result
         })
      });
   }
}

function closeModal() {
   return dispatch => {
      dispatch({
         type: dashboardTypes.IS_MODAL_OPEN,
         payload: {}
      })
   }
}

function removeInvoice(invoiceId, clinicId) {
   return async dispatch => {
      await deleteInvoice(invoiceId,clinicId).then(response => {
         dispatch({
            type: dashboardTypes.IS_DELETE_INVOICE,
            payload: response.result
         })
      });
   }
}
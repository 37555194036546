import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import { useDispatch } from "react-redux";
import { authActions } from "../../../../redux";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";



export default function VerifyToken() {

   const dispatch = useDispatch();

   const [isSubmitting, setIsSubmitting] = useState(false);

   const [token, setToken] = useState("");

   const {
      isRequestAccessToken,
      verifyAuthToken
   } = useAuth();


   const handleEnterTokenChange = (e) => {
      setToken(e.target.value)
   }

   const handleVerifyToken = async() => {
      setIsSubmitting(true)
      await verifyAuthToken(token);
      setIsSubmitting(false)
   }

   return (
      <Dialog
         fullWidth
         maxWidth={'sm'}
         open={isRequestAccessToken}
         onClose={() => dispatch(authActions.closeValidateAccessToken())}
      >
         <DialogTitle>
            Verification Token
         </DialogTitle>

         <DialogContent>
            <TextField
               name="token"
               placeholder="Enter token"
               fullWidth
               onChange={(e) => handleEnterTokenChange(e)}
            />
         </DialogContent>

         <DialogActions disableSpacing>
            <LoadingButton
               variant="contained"
               color="primary"
               loading={isSubmitting}
               disableRipple
               onClick={() => handleVerifyToken()}
            >
               Verify
            </LoadingButton>
         </DialogActions>
      </Dialog>
   )
}
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Tab, TextField, Tooltip, Typography } from "@mui/material";
import useDashboard from "../../../hooks/useDashboard";
import AppointmentsTable from "./sections/appointments-table";
import InvoicesTable from "./sections/invoices-table";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import Iconify from "../../../components/Iconify";
import dayjs from "dayjs";
import moment from "moment";

export default function ClinicInformationModal() {

   const {
      isModalOpen,
      selectedClinicInfo,
      closeModal
   } = useDashboard();

   const [tabIndex, setTabIndex] = useState('appointments')

   const [invoices, setInvoices] = useState([]);

   const [appointments, setAppointments] = useState([]);

   const [invoiceDateFilter, setInvoiceDateFilter] = useState([]);
   const [appointmentDateFilter, setAppointmentDateFilter] = useState([]);

   const [page, setPage] = useState(0)
   const [rowsPerPage, setRowsPerPage] = useState(5);

   const handleTabSelection = (event, tabSelected) => {
      setTabIndex(tabSelected)
   }

   const handleDatePayload = (e, _) => {
      switch (_) {
         case 'invoice_start_date':
            setInvoiceDateFilter({
               ...invoiceDateFilter,
               invoice_start_date: moment(e.$d).format('yyyy-MM-DD')
            });
            break;
         case 'invoice_end_date':
            setInvoiceDateFilter({
               ...invoiceDateFilter,
               invoice_end_date: moment(e.$d).format('yyyy-MM-DD')
            });
            break;
         case 'appointments_start_date':
            setAppointmentDateFilter({
               ...appointmentDateFilter,
               appointments_start_date: moment(e.$d).format('yyyy-MM-DD')
            });
            break;
         case 'appointments_end_date':
            setAppointmentDateFilter({
               ...appointmentDateFilter,
               appointments_end_date: moment(e.$d).format('yyyy-MM-DD')
            });
            break;
         default:
            break;
      }
   }

   //////////////////////////////////// Appointments

   const handleClearAppointmentFilter = () => {
      setAppointments(selectedClinicInfo?.appointments);
      setPage(0)
      setRowsPerPage(5)
   }

   const handleAppointmentsFilter = () => {
      let startDate = new Date(appointmentDateFilter.appointments_start_date);
      let endDate = new Date(appointmentDateFilter.appointments_end_date);
      if (startDate && endDate) {
         const filterAppointments = selectedClinicInfo?.appointments.filter(row => {
            let createdAt = new Date(row.created_at);
            return createdAt >= startDate && createdAt <= endDate;
         });
         setPage(0)
         setRowsPerPage(5)
         setAppointments(filterAppointments)
      }
   }



   ///////////////////////////////////////////// Invoice

   const handleClearInvoiceFilter = () => {
      setInvoices(selectedClinicInfo?.invoices);
   }

   const handleSearchInvoiceNumber = (e) => {
      const filterInvoice = selectedClinicInfo?.invoices.filter(row => {
         let invoiceNumber = row.invoice_number.toLowerCase();
         return invoiceNumber.startsWith(e.target.value.toLowerCase())
      });
      setPage(0)
      setRowsPerPage(5)
      setInvoices(filterInvoice)
   }

   const handleInvoiceFilter = (e) => {
      let startDate = new Date(invoiceDateFilter.invoice_start_date);
      let endDate = new Date(invoiceDateFilter.invoice_end_date);
      if (startDate && endDate) {
         const filterInvoice = selectedClinicInfo?.invoices.filter(row => {
            let createdAt = new Date(row.created_at);
            return createdAt >= startDate && createdAt <= endDate;
         });
         setPage(0)
         setRowsPerPage(5)
         setInvoices(filterInvoice)
      }
   }

   useEffect(() => {
      setAppointments(selectedClinicInfo?.appointments);
      setInvoices(selectedClinicInfo?.invoices)
   }, [])

   return (
      <Dialog
         open={isModalOpen}
         onClose={closeModal}
         fullWidth
         maxWidth={'xl'}
         PaperProps={{
            sx: {
               position: "fixed",
               top: 15
            },
         }}
      >
         <DialogTitle>
            {selectedClinicInfo?.clinic_info?.name}
         </DialogTitle>

         <DialogContent>
            <Box
               sx={{
                  py: 2,
                  px: 2
               }}
            >
               <TabContext value={tabIndex}>
                  <TabList onChange={handleTabSelection}>
                     <Tab
                        label={<Typography variant="subtitle1" sx={{ textTransform: 'none' }}>Appointments</Typography>}
                        value={"appointments"}
                     />

                     <Tab
                        label={<Typography variant="subtitle1" sx={{ textTransform: 'none' }}>Invoices</Typography>}
                        value={"invoices"}
                     />
                  </TabList>
               </TabContext>


               <TabContext value={tabIndex}>
                  <TabPanel value={'appointments'}>
                     <Box
                        component={Paper}
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           py: 2,
                           px: 2
                        }}
                     >
                        <Stack
                           direction={'row'}
                           justifyContent={'flex-end'}
                           alignItems={'center'}
                           spacing={2}
                           sx={{
                              mb: 3
                           }}
                        >
                           <DatePicker
                              label={'Start Date'}
                              name="start_date"
                              onChange={(e) => handleDatePayload(e, 'appointments_start_date')}
                              defaultValue={dayjs(new Date())}
                              slotProps={{
                                 textField: {
                                    size: "small",
                                 },
                              }}
                           />
                           <DatePicker
                              label={'End Date'}
                              onChange={(e) => handleDatePayload(e, 'appointments_end_date')}
                              name="end_date"
                              defaultValue={dayjs(new Date())}
                              slotProps={{
                                 textField: {
                                    size: "small",
                                 },
                              }}
                           />
                           <Tooltip title="Filter Appointments">
                              <IconButton onClick={() => handleAppointmentsFilter()}>
                                 <Iconify icon={'basil:filter-outline'} width={20} height={20} />
                              </IconButton>
                           </Tooltip>

                           <Tooltip title="Clear Filter">
                              <IconButton onClick={(e) => handleClearAppointmentFilter(e)}>
                                 <Iconify icon={'pajamas:clear-all'} width={20} height={20} />
                              </IconButton>
                           </Tooltip>
                        </Stack>

                        <AppointmentsTable
                           appointments={appointments}
                           page={page}
                           setPage={setPage}
                           rowsPerPage={rowsPerPage}
                           setRowsPerPage={setRowsPerPage}
                        />
                     </Box>
                  </TabPanel>
               </TabContext>

               <TabContext value={tabIndex}>
                  <TabPanel value={'invoices'}>
                     <Box
                        component={Paper}
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           py: 2,
                           px: 2
                        }}
                     >
                        <Stack
                           direction={'row'}
                           justifyContent={'space-between'}
                           alignItems={'center'}
                           sx={{
                              mb: 3
                           }}
                        >
                           <TextField
                              label='Search invoice number'
                              size="small"
                              onChange={(e) => handleSearchInvoiceNumber(e)}
                           />

                           <Stack
                              direction={"row"}
                              spacing={2}
                           >
                              <DatePicker
                                 label={'Start Date'}
                                 name="start_date"
                                 onChange={(e) => handleDatePayload(e, 'invoice_start_date')}
                                 defaultValue={dayjs(new Date())}
                                 slotProps={{
                                    textField: {
                                       size: "small",
                                    },
                                 }}
                              />
                              <DatePicker
                                 label={'End Date'}
                                 onChange={(e) => handleDatePayload(e, 'invoice_end_date')}
                                 name="end_date"
                                 defaultValue={dayjs(new Date())}
                                 slotProps={{
                                    textField: {
                                       size: "small",
                                    },
                                 }}
                              />
                              <Tooltip title="Filter Date">
                                 <IconButton onClick={(e) => handleInvoiceFilter(e)}>
                                    <Iconify icon={'basil:filter-outline'} width={20} height={20} />
                                 </IconButton>
                              </Tooltip>

                              <Tooltip title="Clear Filter">
                                 <IconButton onClick={(e) => handleClearInvoiceFilter(e)}>
                                    <Iconify icon={'pajamas:clear-all'} width={20} height={20} />
                                 </IconButton>
                              </Tooltip>

                           </Stack>
                        </Stack>
                        <InvoicesTable
                           invoices={invoices}
                           page={page}
                           setPage={setPage}
                           rowsPerPage={rowsPerPage}
                           setRowsPerPage={setRowsPerPage}
                        />
                     </Box>
                  </TabPanel>
               </TabContext>
            </Box>
         </DialogContent>

         <DialogActions>
            <Button
               variant="contained"
               color="error"
               disableRipple
               onClick={closeModal}
            >
               Close
            </Button>
         </DialogActions>
      </Dialog>
   )
}
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { Navigate } from 'react-router-dom';

AuthGuard.propTypes = {
   children: PropTypes.node
}

export default function AuthGuard({ children }) {

   const { isAuthenticated } = useAuth();

   if (!isAuthenticated) {
      return <Navigate to={'/auth/login'} />
   }

   return <>{children}</>;
}
import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";



export default function DashboardLayout() {


   return (
      <Container maxWidth={'lg'}>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               flexGrow: 1,
               height: '100vh',
            }}
         >
            <Outlet />
         </Box>
      </Container>
   )
}
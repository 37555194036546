import {
   Box,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TablePagination,
   TableRow,
   Typography
} from "@mui/material"
import moment from "moment";
import { useState } from "react";


export default function AppointmentsTable({ ...props }) {

   const handleChangePage = (e, newPage) => {
      props.setPage(newPage)
   }

   const rows = () => {
      if (props.appointments.length > 0) {
         props.appointments.sort((a, b) => moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1)
         return props.appointments;
      }
      return props.appointments;
   };

   const handleChangeRowsPerPage = (e) => {
      props.setRowsPerPage(parseInt(e.target.value));
      props.setPage(0);
   }

   return (
      <>
         {
            (rows()?.length <= 0) &&
            <Box
               display={"flex"}
               justifyContent={"center"}
               sx={{ px: 20, py: 20 }}
            >
               <Typography variant="h6">No Appointments</Typography>
            </Box>
         }

         {
            (rows()?.length > 0) &&
            <TableContainer sx={{ minWidth: '50vh', maxHeight: '50vh' }}>
               <Table stickyHeader>
                  <TableHead>
                     <TableRow>
                        {
                           tableHeader.map((row) => (
                              <TableCell align="left" key={row.id}>
                                 <Typography variant="subtitle2" color={'primary'} fontWeight={700}>{row.label}</Typography>
                              </TableCell>
                           ))
                        }
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {
                        rows()?.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                           .map(row => (
                              <TableRow
                                 key={row.id}
                                 hover
                                 sx={{
                                    '&:last-child td, &:last-child th': {
                                       border: 0
                                    },
                                    cursor: 'pointer'
                                 }}
                              >
                                 <TableCell align="left">
                                    <Typography variant="subtitle1">{row.id}</Typography>
                                 </TableCell>

                                 <TableCell align="left">
                                    {row?.status}
                                 </TableCell>

                                 <TableCell align="left">
                                    {moment(row.created_at).format('MM/DD/yyyy')}
                                 </TableCell>
                              </TableRow>
                           ))
                     }
                  </TableBody>
               </Table>
            </TableContainer>
         }

         <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={props.appointments.length}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
               mt: 5
            }}
         />
      </>
   )
}

const tableHeader = [
   { id: 1, label: 'Appointment ID' },
   { id: 2, label: 'Status' },
   { id: 3, label: 'Created' }
]